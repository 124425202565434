import React from "react";
import { IoIosHome } from "react-icons/io";
// import { BsChevronDown } from "react-icons/bs";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import logo from "./logo.png";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
  
      // Adjust the threshold value to control when the background appears
      if (scrollPosition > 30) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  // console.log(scroll)

  const navbarClassName = scroll
    ? "navbar navbar-expand-lg navbar-white p-md-0 fixed-top bg-white"
    : "navbar navbar-expand-lg navbar-white p-md-0 fixed-top";

  return (
    <>
      <nav className={navbarClassName} style={{ marginBottom: "0 0 0 0" }}>
        <div className="container my-1 mx-8">
          <Link to="/">
          <img
            alt="ImageHere"
            src={logo}
            width="220"
            height="100"
            id="img123"
            // style={{ marginLeft: "2%" }}
            />

          </Link>
          <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ backgroundColor: 'grey' }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <div className="mx-auto"></div>
            <ul className="navbar-nav" style={{ marginRight: "5%" }}>
              <li className="nav-item">
                <a
                  href="https://www.usefulbi.com/case-studies/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {
                    /* <Link to="/casestudies"> */
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="default btn-default dropdown-toggle btn-lg"
                        id="mybyn"
                        style={{
                          color: "black",
                          marginRight: "6rem",
                          fontWeight: "500",
                          border: "0px",
                        }}
                      >
                        Case Studies
                      </Dropdown.Toggle>
                    </Dropdown>
                    /* </Link> */
                  }
                </a>
              </li>

              <li className="nav-item" style={{}}>
                <div className="tooltip-container">
                  <a
                    href="https://usefulbi.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoIosHome size={45} color="#1D165C" />
                  </a>
                  <span className="tooltip-text">Go to UsefulBi Homepage</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </>
  );
};

export default Navbar;
