// import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar.js";
import header from "./Pages/Usecase/component/images/Header.png";
import Usecase from "./Pages/Usecase/Usecase";
import Lifescience from "./Pages/Lifesciences/lifescience";
import Dataengineering from "./Pages/dataengineering/dataengineering";
import Datascience from "./Pages/datascience/datascience";
import Businessintelligence from "./Pages/businessintelligence/bi";
import Automotive from "./Pages/Automotive/BFSI";
import Retail from "./Pages/Retail/BFSI";
import CaseStudies from "./Pages/CaseStudies/CaseStudies";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Industry from "./Pages/Industry/Industry";
import BFSI from "./Pages/BFSI/BFSI";
import Tableau from "./Pages/Tableau/Tableau";
import Reporting1 from "./Pages/BFSI/Reporting";
import Reporting2 from "./Pages/Retail/Reporting";
import Reporting3 from "./Pages/Automotive/Reporting";
import Reporting4 from "./Pages/businessintelligence/Reporting";
import PowerBI from "./Pages/PowerBI/PowerBI";
import MultipleSelectCheckmarks from "./components/search-bar/search-bar.js";
import Card from "./components/cards/Card.js";
import { data as data1 } from "./Pages/Lifesciences/data";
import { data as data2 } from "./Pages/BFSI/data";
import { data as data3 } from "./Pages/Retail/data";
import { data as data4 } from "./Pages/Automotive/data";
import { data as data5 } from "./Pages/dataengineering/data";
import { data as data6 } from "./Pages/datascience/data";
import { data as data7 } from "./Pages/businessintelligence/data";
import Login from "./Pages/login/Login";

function App() {
  // const ScrollToTop = () => {
  //   const location = useLocation();
  //   useEffect(() => {
  //     // Determine the active button based on the current route
  //     // if (window.location.pathname === "/") {
  //     //   setActiveButton("usecase");
  //     // }
  //     // else if
  //     if (location.pathname === "/industry") {
  //       setActiveButton("usecase");
  //     } else if (location.pathname === "/usecase") {
  //       setActiveButton("industry");
  //     }
  //   }, [location.pathname]);
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, [location]);

  //   return null;
  // };
  const [activeButton, setActiveButton] = useState("usecase");

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };
  const [data, setData] = useState([
    ...data1,
    ...data2,
    ...data3,
    ...data4,
    ...data5,
    ...data6,
    ...data7,
  ]);

  const [industryArray, setIndustryArray] = useState([
    "Lifesciences",
    "BFSI",
    "Automotive",
    "Retail",
  ]);
  const [useCaseArray, setUseCaseArray] = useState([
    "Data Engineering",
    "Data Science",
    "Business Intelligence",
    "Gen AI",
  ]);

  //  useEffect(() =>{
  //   setIndustryArray([...data.filter((card) => card.type === "industry")])
  //   setUseCaseArray([...data.filter((card) => card.type === "usecase")])
  //  },[data])

  const uniqueTags = Array.from(new Set(data.map((t) => t.tag).flat()));
  const [filteredCards, setFilteredCards] = useState([]);
  // console.log(filteredCards);

  // const industryArray =  data.filter((card) => card.type === "industry");
  // const usecaseArray = data.filter((card) => card.type === "usecase");

  // console.log(industryArray);
  // console.log(useCaseArray);

  useEffect(() => {
    setFilteredCards([]); // Initialize with all cards
  }, []);

  // const handleSearch = (selectedTags) => {
  //   if (selectedTags.length > 0) {
  //     let filtered = [];

  //     if (industryArray.some(tag => selectedTags.includes(tag.name))) {
  //       filtered = usecaseArray.filter((card) =>
  //         selectedTags.some((tag) => card.tag && card.tag.includes(tag))
  //       );
  //     } else if (usecaseArray.some(tag => selectedTags.includes(tag.name))) {
  //       filtered = industryArray.filter((card) =>
  //         selectedTags.some((tag) => card.tag && card.tag.includes(tag))
  //       );
  //     }

  //     setFilteredCards(filtered);
  //   } else {
  //     setFilteredCards([]); // Clear cards if no tag is selected
  //   }
  // };

  const handleSearch = (selectedTags) => {
    if (selectedTags.length > 0) {
      let filtered = data
        .filter((card) =>
          selectedTags.some((tag) => card.tag && card.tag.includes(tag))
        )
        .reduce((acc, current) => {
          const x = acc.find((item) => item.name === current.name);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

      let indFlag = selectedTags.some((tag) => industryArray.includes(tag));
      let useFlag = selectedTags.some((tag) => useCaseArray.includes(tag));

      filtered = filtered.map((card) => {
        if (indFlag && !useFlag) {
          // If selected tags are only from industryArray, display corresponding usecase tags
          const correspondingTags = card.tag.filter(
            (tag) => !industryArray.includes(tag)
          );
          return {
            ...card,
            tag: correspondingTags.length > 0 ? correspondingTags : card.tag,
          };
        } else if (useFlag && !indFlag) {
          // If selected tags are only from useCaseArray, display corresponding industry tags
          const correspondingTags = card.tag.filter(
            (tag) => !useCaseArray.includes(tag)
          );
          return {
            ...card,
            tag: correspondingTags.length > 0 ? correspondingTags : card.tag,
          };
        }
        // else if (!useFlag && !indFlag) {
        //   // If selected tags are only from useCaseArray, display corresponding industry tags
        //   const correspondingTags = card.tag.filter((tag) =>
        //     selectedTags.includes(tag)
        //   );
        //   return {
        //     ...card,
        //     tag: correspondingTags.length > 0 ? correspondingTags : card.tag,
        //   };
        // }
        return card;
      });

      setFilteredCards(filtered);
    } else {
      setFilteredCards([]); // Clear cards if no tag is selected
    }
  };

  // const apiUrl = process.env.REACT_APP_LAMBDA_URL;
  // console.log("API URL:", apiUrl);

  // console.log(filteredCards);
  return (
    <Router>
      <div className="App">
        {/* <ScrollToTop /> */}
        <Navbar />
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/lifescience">
            <Lifescience />
          </Route>
          <Route exact path="/BFSI">
            <BFSI />
          </Route>
          <Route exact path="/dataengineering">
            <Dataengineering />
          </Route>
          <Route exact path="/datascience">
            <Datascience />
          </Route>
          <Route exact path="/businessintelligence">
            <Businessintelligence />
          </Route>
          <Route exact path="/automotive">
            <Automotive />
          </Route>
          <Route exact path="/retail">
            <Retail />
          </Route>
          <Route exact path="/casestudies">
            <CaseStudies />
          </Route>
          <Route exact path="/tableau">
            <Tableau />
          </Route>
          <Route exact path="/reporting1">
            <Reporting1 />
          </Route>
          <Route exact path="/reporting2">
            <Reporting2 />
          </Route>
          <Route exact path="/reporting3">
            <Reporting3 />
          </Route>
          <Route exact path="/reporting4">
            <Reporting4 />
          </Route>
          <Route exact path="/powerbi">
            <PowerBI />
          </Route>
          <Route>
            <div className="banner-image d-flex justify-content-center align-items-center"></div>

            <h1 style={{ textAlign: "center", marginTop: "40px" }}>
              <span style={{ color: "#3993DD" }}>UBI DEMO</span>{" "}
              <span style={{ color: "#1D165C" }}>CATALOGUE</span>
            </h1>
            <img
              src={header}
              alt=""
              style={{
                display: "block",
                margin: "0 auto",
                maxWidth: "100%",
                height: "auto",
                width: "420px",
              }}
              />
            <br />
            <br />

            <div className="search">
              {/* <div className="search-bar-container"> */}
                <MultipleSelectCheckmarks
                  names={uniqueTags}
                  onSearch={handleSearch}
                  />
              {/* </div> */}
            </div>
            <div className="row justify-content-center">
              {filteredCards.length > 0 &&
                filteredCards.map((x) => (
                  <Card
                  key={x.id}
                  name={x.name}
                  icon={x.icon}
                  description={x.description}
                  link={x.link}
                  link2={x.link2}
                  btntxt={x.btntxt}
                  fontSize={x.fontSize}
                  imgheight={x.imgheight}
                  imgwidth={x.imgwidth}
                  tag={x.tag}
                  {
                    ...x
                  }
                  />
                ))}
            </div>

            <br />
            <br />
            {filteredCards.length === 0 && (
              <div className="d-flex justify-content-center">
                <Link to="/industry">
                  <button
                    type="button"
                    id="buttoz"
                    className={`btn btn-lg btn mx-2 ${
                      activeButton === "usecase" ? "active1" : ""
                    }`}
                    style={{
                      backgroundColor: "#DCDCDC",
                      width: "30rem",
                      fontWeight: "600",
                    }}
                    onClick={() => handleButtonClick("usecase")}
                    >
                    By Industry
                  </button>
                </Link>
                <Link to="/usecase">
                  <button
                    type="button"
                    id="buttoz"
                    className={`btn btn-lg mx-2 ${
                      activeButton === "industry" ? "active1" : ""
                    }`}
                    style={{
                      backgroundColor: "#DCDCDC",
                      width: "30rem",
                      fontWeight: "600",
                    }}
                    onClick={() => handleButtonClick("industry")}
                    >
                    By Use Case
                  </button>
                </Link>
              </div>
            )}
          </Route>
        </Switch>
        {filteredCards.length === 0 && (
          <Switch>
            <Route exact path="/industry">
              <Usecase />
            </Route>
            <Route exact path="/">
              <Usecase />
            </Route>
            <Route exact path="/usecase">
              <Industry />
            </Route>
          </Switch>
        )}
      </div>
       
    </Router>
  );
}

export default App;
